import Script from "next/script";

const GoogleTagLoader = () => {
  return (
    <>
      <Script id="google-tag-manager" strategy="afterInteractive">
        {`
          (function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
          new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
          j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
          'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
          })(window,document,'script','dataLayer','GTM-PNM8RSQC');
        `}
      </Script>

      <Script id="google-tag-manager-noscript" strategy="afterInteractive">
        {`
          if (typeof window !== 'undefined') {
            const noscript = document.createElement('noscript');
            const iframe = document.createElement('iframe');
            iframe.src = "https://www.googletagmanager.com/ns.html?id=GTM-PNM8RSQC";
            iframe.height = "0";
            iframe.width = "0";
            iframe.style.display = "none";
            iframe.style.visibility = "hidden";
            noscript.appendChild(iframe);
            document.body.appendChild(noscript);
          }
        `}
      </Script>
    </>
  );
};

export default GoogleTagLoader;
