import GoogleTagLoader from "@/scripts/GoogleTagLoader";
import ToltLoader from "@/scripts/ToltLoader";
import PosthogLoader from "@/scripts/PosthogLoader";
import "@/styles/globals.css";
import type { AppProps } from "next/app";
import localFont from "next/font/local";
// import "@/styles/blog-styles.css";

const ambit = localFont({
  src: [
    {
      path: "../public/fonts/ambit-regular.otf",
      weight: "400",
      style: "normal",
    },
    {
      path: "../public/fonts/ambit-italic.otf",
      weight: "400",
      style: "italic",
    },
    {
      path: "../public/fonts/ambit-light.otf",
      weight: "300",
      style: "normal",
    },
    {
      path: "../public/fonts/ambit-light-italic.otf",
      weight: "300",
      style: "italic",
    },
    {
      path: "../public/fonts/ambit-bold.otf",
      weight: "700",
      style: "normal",
    },
    {
      path: "../public/fonts/ambit-bold-italic.otf",
      weight: "700",
      style: "italic",
    },
    {
      path: "../public/fonts/ambit-black.otf",
      weight: "900",
      style: "normal",
    },
    {
      path: "../public/fonts/ambit-black-italic.otf",
      weight: "900",
      style: "italic",
    },
    {
      path: "../public/fonts/ambit-extralight.otf",
      weight: "200",
      style: "normal",
    },
    {
      path: "../public/fonts/ambit-extralight-italic.otf",
      weight: "200",
      style: "italic",
    },
    {
      path: "../public/fonts/ambit-semibold-italic.otf",
      weight: "600",
      style: "italic",
    },
  ],
  variable: "--font-ambit",
});

export default function App({ Component, pageProps }: AppProps) {
  return (
    <main className={`${ambit.variable} font-sans`}>
      <ToltLoader />
      <GoogleTagLoader />
      <PosthogLoader />
      <Component {...pageProps} />
    </main>
  );
}
