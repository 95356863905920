import Script from "next/script";

const ToltLoader = () => {
  return (
    <Script
      strategy="lazyOnload"
      src="https://cdn.tolt.io/tolt.js"
      data-tolt="0596b247-02ac-409d-8f67-0838dd1b8fbd"
    />
  );
};

export default ToltLoader;
